import request from '@/services/request'
import { getApiUrl } from '@/utils/tool'
export function getPaytype() {
  return request({
    url: '/3tPaytype/getPaytype',
    method: 'post'
  })
}
export function addStep1(data) {
  return request({
    url: '/3tOrder/addStep1',
    method: 'post',
    data: data
  })
}
export function addStep2(data) {
  return request({
    url: '/3tOrder/addStep2',
    method: 'post',
    data: data
  })
}
export function reversalFullRedeem(orders_happygo_id) {
  return request({
    url: '/3tHappygo/reversalFullRedeem',
    method: 'post',
    data: {
      orders_happygo_id: orders_happygo_id
    }
  })
}
export function getOrder(order_num) {
  return request({
    url: '/3tOrder/getOrder/' + order_num,
    method: 'get'
  })
}

export function getOrderstatus(payment, order_id) {
  return request({
    baseURL: getApiUrl(true),
    url: `/payment/${payment}/check?id=${order_id}`,
    method: 'get'
  })
}

export function checkPaid(data) {
  return request({
    url: '/3tOrder/checkPaid',
    method: 'post',
    data
  })
}
