<template>
  <div class="inter-wrapper">
    <HeaderInter :showbackbtn="showbackbtn" />
    <div class="ckpaid-wrapper">
      <img src="@/assets/images/icon_ckpaid.svg" width="120" height="120">
      <div class="snippet" data-title=".dot-flashing">
        <div class="stage">
          <div class="dot-flashing" />
        </div>
      </div>
      <div class="confirm-text">
        付款確認中
      </div>
      <div class="warning-text">
        請勿離開此頁面
      </div>
    </div>
  </div>
</template>
<script>
import HeaderInter from '@/components/HeaderInter'
import { checkPaid } from '@/api/payment'

export default {
  components: {
    HeaderInter
  },
  data() {
    return {
      credit_num: '',
      checkpaidtimer: null,
      issucc: false,
      checktime: 3000,
      checknum: 0,
      limitnum: 6,
      showbackbtn: false
    }
  },
  async created() {
    // id
    this.credit_num = this.$route.query.id
    const requestdata = { id: this.credit_num }
    const self = this
    this.checkpaidtimer = window.setInterval(async function() {
      self.checknum += 1
      try {
        const res = await checkPaid(requestdata)
        if (res.data.succ) {
          clearInterval(self.checkpaidtimer)
          // 500毫秒後轉頁
          setTimeout(() => {
            self.$router.push({
              path: '/order/finish',
              query: { order_num: res.data.data.order_num }
            })
          }, 500)
        }
      } catch (error) {
        console.log(error)
      }
      if (self.checknum >= self.limitnum) {
        clearInterval(self.checkpaidtimer)
        self.$router.push('/order/fail')
      }
    }, self.checktime)
  }
}
</script>
<style lang="scss">
.ckpaid-wrapper {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
  position: relative;
  .snippet {
    margin-top: 10px;
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #26b7bc;
    color: #26b7bc;
    animation: dotFlashing 1s infinite;
  }
  .confirm-text {
    margin: 30px 0;
    font-weight: 700;
    font-size: 1.2rem;
    color: #545453;
  }
  .warning-text {
    color: #e32d2d;
    position: absolute;
    font-weight: 700;
    bottom: 20vh;
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #26b7bc;
  color: #26b7bc;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #26b7bc;
  color: #26b7bc;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #26b7bc;
  color: #26b7bc;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #26b7bc;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
</style>
